<template>
  <b-container class="container-rda sidebar home">
    <b-row class="height">
      <b-col md="2" class="menu-sidebar">
        <div class="menu">
          <ul>
            <li class="side-item" v-for="subject in subjectList" :key="subject.id"
              :class="{ active: subject_id === subject.id }" @click="() => selectSubject(subject.id)">
              <span>
                <SubjectsIcon :value="subject.designation" />
                {{ subject.designation }}
              </span>
            </li>
          </ul>
        </div>
      </b-col>

      <b-col class="content">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>

        <b-row class="mb-5">
          <b-col>
            <h2 class="purple bold">Registro de Aprendizagem</h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-row class="profile-row">
              <b-col>
                <img v-if="photo == null || ''" src="@/assets/images/default-photo.png" height="64" width="64" alt />
                <img v-else width="64" height="64" :src="`${photo}`" alt />

                <div class="content-meeting">
                  <div class="column">
                    <h5>{{ studentName }}</h5>
                    <p>
                      <strong>Disciplina:</strong>
                      {{ subject.designation }}
                    </p>
                  </div>

                  <div class="column two">
                    <p>
                      <strong>Ciclo:</strong>
                      {{ cycleYear }}º ano do
                      {{ cycle }}
                    </p>
                    <p>
                      <strong>Evolução em relação ao currículo:</strong>
                      <span class="learning-progress">
                        {{ subject.percentage }}%
                        <b-progress :value="subject.percentage" class="white"></b-progress>
                      </span>
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="content-meeting">
                  <div class="column" style="padding-top: 30px">
                    <p><strong>Legenda:</strong></p>
                    <p><strong>A:</strong> Conhecimento aprendido</p>
                    <p>A<strong>N:</strong> Conhecimento ainda não aprendido</p>
                    <p>
                      <strong>*:</strong> Conhecimento supostamente alcançado em
                      outro estabelecimento de ensino
                    </p>
                    <p><strong>#:</strong> Dispensa de conteúdo curricular</p>
                    <p><strong>EE:</strong> Em estudo</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <template v-if="subject_id">
          <div v-for="(comp, competence_number) in competences" :key="comp.id" class="comp-list">
            <b-row class="mb-4" style="margin-left: 0; margin-right: 0;">
              <b-col>
                <b-row>
                  <h4 class="bold blue" v-if="comp.details" style="color: #b23066; font-weight: 700;">
                    {{ comp.details }}
                  </h4>
                </b-row>
                <b-row>
                  <h4 class="bold blue" v-if="comp.TematicUnit" style="color: #68c5b7; font-weight: 700;">
                    {{ competence_number + 1 }} - Unidade Temática: {{ comp.TematicUnit.description }}
                  </h4>
                </b-row>
                <b-row>
                  <h5 class="bold blue">
                    Competência: {{ comp.description }}
                  </h5>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-container fluid>
                  <b-row class="header-list-teacher">
                    <b-col md="8">
                      <p class="bold">Conteúdos</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Situação</p>
                    </b-col>
                    <b-col md="1">
                      <p class="bold">Avaliação</p>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid class="list" v-for="(topic, topic_number) in comp.Topics" :key="topic.id">
                  <b-row align-v="center" class="list-teacher">
                    <b-col md="8">
                      <p style="font-weight: 700;">
                        {{ competence_number + 1 }}.{{ topic_number + 1 }} - {{ topic.KnowledgeObject ? `Objeto de
                        Conhecimento:
                        ${topic.KnowledgeObject.description}` : '' }}
                      </p>
                      <p>
                        Conteúdo: {{ topic.description }}
                        <span class="red-asterisk" v-if="topic.bncc_code != ''">*</span>
                      </p>
                      <div class="prof-data" v-if="topic.TopicStudents[0].editor_user_id !== null">
                        <span>Atualizado por
                          <b>{{ topic.TopicStudents[0].editor ? topic.TopicStudents[0].editor.name : '' }} •
                            {{ formatDate(topic.TopicStudents[0].updated_at) }}</b></span>
                      </div>
                    </b-col>

                    <b-col style="display: flex; flex-direction: row">
                      <p class="learning-tags" style="width: 100px">
                        <span>{{ topic.TopicStudents[0].status }}</span>
                      </p>
                      <b-button :id="topic.TopicStudents[0].topic_id" class="evidence-btn"
                        v-if="topic.TopicStudents[0].status === 'AN'"
                        @click="showEvidence(topic.TopicStudents[0].pendency)">
                        Ver pendência
                      </b-button>
                    </b-col>
                    <b-col md="1">
                      <span class="for-evaluation" @click="() =>
                        updateTopic({
                          is_for_evaluation:
                            !topic.TopicStudents[0].is_for_evaluation,
                          topic_id: topic.id,
                          message: topic.TopicStudents[0].is_for_evaluation
                            ? 'cancelada!'
                            : 'enviada',
                          topicNumber: topic_number,
                          compNumber: competence_number,
                        })
                        ">
                        <RateIcon :class="{
                          active: topic.TopicStudents[0].is_for_evaluation,
                        }" />
                      </span>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-col>
    </b-row>
    <b-modal id="modal-evidence" hide-footer style="display: flex; justify-content: center; align-items: center">
      <h3>Pendências</h3>

      <div class="pendency-text">
        <p>{{ pendencyText }}</p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import RateIcon from "@/components/svg/RateIcon.vue";
import SubjectsIcon from "@/components/svg/SubjectsIcon.vue";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import httpRda from "../../http-rda";

export default {
  name: "registrar-aprendizagens-aluno",
  components: {
    SubjectsIcon,
    RateIcon,
  },

  data() {
    return {
      subject_id: null,
      pendencyText: null,
      learningStatus: ["EE", "AN", "A", "*", "#"],
      competences: [],
      subjectList: [],
      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Registro de Aprendizagem",
          href: "/aprendizagem/conteudos",
        },
        {
          text: "Aluno",
          href: "/aprendizagem/registrar-aprendizagens",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      student: "learning/student",
      // subjectList: "learning/subjectContent",
    }),

    student_id() {
      return this.$route.params.id;
    },

    studentName() {
      if (!this.student.User) return "";
      return this.student.User.name;
    },

    photo() {
      if (!this.student.User) return "";
      return this.student.User.photo;
    },

    subject() {
      const { subject_id, subjectList } = this;

      if (!subject_id) return { designation: "-" };
      return subjectList.find((s) => s.id === subject_id);
    },

    cycle() {
      return this.student.Cycle.designation;
    },

    cycleYear() {
      return this.student.year_id ? this.student.year_id : "";
    },
  },

  methods: {
    ...mapActions({
      getStudent: "learning/getStudent",
      updateStudentTopicEvaluation: "learning/updateStudentTopicEvaluation",
    }),

    showEvidence(pendency) {
      this.pendencyText = pendency;

      this.$bvModal.show("modal-evidence");
    },

    formatDate(date) {
      return moment(date)
        .tz("America/Sao_Paulo")
        .format("DD/MM/YYYY [às] HH:mm");
    },

    async selectSubject(subject_id) {
      this.subject_id = subject_id;
      this.competences = this.subjectList.find(
        (s) => s.id === subject_id
      ).Competences;
    },

    async getAllSubjects() {
      await httpRda
        .get(`/students/${this.student_id}/subjects?include=topics,cycle`)
        .then((response) => {
          this.subjectList = response.data.subjects;
        });
    },

    async updateTopic({ topic_id, ...data }) {
      try {
        await this.updateStudentTopicEvaluation({
          student_id: this.student_id,
          topic_id,
          ...data,
        });
        await this.getAllSubjects({ student_id: this.student_id });

        this.competences[data.compNumber].Topics[
          data.topicNumber
        ].TopicStudents[0].is_for_evaluation = data.is_for_evaluation;

        this.$toast.open({
          message: `Solicitação de avaliação ${data.message}`,
          type: "success",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.open({
          message: "Não foi possivel solicitar a avaliação!",
          type: "error",
          duration: 2000,
        });
      }
    },
  },

  async created() {
    await this.getStudent(this.student_id);
    await this.getAllSubjects(this.student_id);
    if (this.subjectList.length) this.subject_id = this.subjectList[0].id;
    this.competences = this.subjectList.find(
      (s) => s.id === this.subject_id
    ).Competences;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.evidence-btn {
  border-radius: 5px;
  background-color: #68c5b7;
  color: white;
  border: none;
}

.pendency-text {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

ul,
li {
  padding: 0;
}

.home {
  height: 100%;
}

.blue {
  color: $blue;
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;

  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }

  .col {
    display: flex;
    align-items: center;

    img {
      margin-right: 40px;
    }

    .content-meeting {
      display: flex;
      width: 100%;

      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 60px;
        min-width: 250px;
        margin-right: 30px;

        &.two {
          min-width: 650px;
        }

        .learning-progress {
          display: inline-flex;
          align-items: center;
          margin-left: 10px;
          width: 50%;

          .progress {
            width: 100%;
            margin-left: 15px;

            &.white {
              background-color: transparent;
              border: 1px solid $white;

              .progress-bar {
                background-color: $white;
              }
            }
          }
        }
      }

      h5 {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }
  }
}

.progress {
  border-radius: 30px;
  height: 7px;

  .progress-bar {
    background-color: $purple;
  }

  &.orange {
    .progress-bar {
      background-color: $orange2;
    }
  }

  &.yellow {
    .progress-bar {
      background-color: $yellow;
    }
  }

  &.red {
    .progress-bar {
      background-color: $red;
    }
  }

  &.green {
    .progress-bar {
      background-color: $green2;
    }
  }
}

.container-rda {
  padding-bottom: 0;
}

.learning-date {
  background-color: $purple;
  border-radius: 6px;
  color: $white;
  line-height: 28px;
  max-width: 82px;
  text-align: center;
  width: 100%;
}

.learning-tags {
  font-size: 0;

  span {
    background-color: $grey5;
    border-radius: 6px;
    color: $white;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    margin-right: 12px;
    max-width: 34px;
    width: 100%;
    text-align: center;

    &.active {
      background-color: $blue;
    }
  }
}

.comp-list {
  &+.comp-list {
    margin-top: 30px;
  }
}

.prof-data {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 4px;

  span {
    font-size: 12px;
    color: #939393;

    b {
      font-weight: bold;
    }
  }

  time {
    font-size: 10px;
    color: #939393;
  }
}
</style>

<style lang="scss">
.for-evaluation {
  svg {
    cursor: pointer;
  }

  svg:not(.active) path {
    fill: gray;
  }
}
</style>

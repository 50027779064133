<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold title">
          Alunos <span class="tags_count">{{ count }}</span>
        </h2>
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col>
        <SelectCycles v-model="cycle" :callback="callbackCycles"></SelectCycles>
        <span class="clear-field" v-if="this.cycle != null" @click="clearCycles">Limpar filtro</span>
      </b-col>
      <b-col>
        <SelectClassrooms v-model="classroom" :callback="callbackClassrooms" :cycle="cycle_select"></SelectClassrooms>
        <span class="clear-field" v-if="this.classroom != null" @click="clearClassromms">Limpar filtro</span>
      </b-col>

      <b-col>
        <SelectStudentsStatus v-model="is_active" :callback="callbackStudentsStatus"></SelectStudentsStatus>
        <span class="clear-field" v-if="this.is_active != null" @click="clearStudentsStatus">Limpar filtro</span>
      </b-col>

      <b-col>
        <b-form-group label="Buscar aluno">
          <b-form-input type="search" size="sm" placeholder="Digite aqui" v-model="search"
            @change="searchStudent"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-container class="list-top" fluid>
          <b-row class="header-list-teacher" v-if="wasRequestMade && students.length">
            <b-col :md="8">
              <p class="bold">Nome</p>
            </b-col>
            <b-col>
              <p class="bold">Ciclo</p>
            </b-col>
            <b-col>
              <p class="bold">Relatório Informe Periódico</p>
            </b-col>
            <b-col>
              <p class="bold">Relatório Exportação</p>
            </b-col>
            <b-col>
              <p class="bold">Tutoria</p>
            </b-col>
          </b-row>
        </b-container>

        <b-container fluid>
          <b-row align-v="center" class="list-teacher" v-for="student in students" :key="student.id">
            <b-col :md="8" class="name">
              <p class>{{ student.User.name }}</p>
            </b-col>
            <b-col class="cycles">
              <p class="tags">{{ student.Cycle.name }}</p>
            </b-col>
            <b-col>
              <b-button class="btn-period" :disabled="loading" @click="downloadStudentReport(student.id)"
                variant="primary">
                <ExportIcon />
              </b-button>
            </b-col>
            <b-col style="display: flex; align-items: center; justify-content: center;">
              <router-link :to="{
                name: 'exportar-relatorio-desenvolvimento-antigo',
                params: { id: student.id },
              }">
                <ExportIcon2 />
              </router-link>
            </b-col>
            <b-col style="display: flex; align-items: center; justify-content: center;">
              <router-link :to="{
                name: 'dashboard-tutoria-antiga-aluno',
                params: { id: student.id },
              }">
                <ViewIcon />
              </router-link>
            </b-col>
          </b-row>

          <b-row align-v="center" class="list-teacher" v-if="wasRequestMade && !students.length">
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!wasRequestMade">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row class="align-center" v-if="!search && wasRequestMade">
            <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="count" aria-controls="teachersList"
              last-text first-text>
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>

          <StudentEditData :student="studentToEdit" />

          <StudentEditPassword :student="studentToPassword" />
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import ExportIcon from "@/components/svg/ExportIcon.vue";
import ExportIcon2 from "@/components/svg/ExportIcon2.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectClassrooms from "@/components/inputs/SelectClassrooms.vue";
import SelectStudentsStatus from "@/components/inputs/SelectStudentsStatus.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import StudentEditData from "@/components/modals/StudentEditData.vue";
import StudentEditPassword from "@/components/modals/StudentEditPassword.vue";
import HttpRda from "../../http-rda";
import moment from "moment-timezone";
export default {
  name: "alunos-registros-antigos",
  components: {
    ViewIcon,
    ExportIcon,
    ExportIcon2,
    LeftArrowIcon,
    SelectClassrooms,
    SelectStudentsStatus,
    SelectCycles,
    StudentEditData,
    StudentEditPassword,
  },

  data() {
    return {
      nameCol: "Tutoria",
      descOrder: false,
      wasRequestMade: false,
      studentToEdit: {},
      studentToPassword: {},
      search: "",
      filter: null,
      cycle_select: null,
      cycle: null,
      classroom: null,
      is_active: true,
      searchTerm: "",
      students: null,
      currentPage: 1,
      count: 0,
      perPage: 10,
      loading: false,
      breadcrumb: [
        {
          text: "Administração",
          href: "/administração",
        },
        {
          text: "Registros Antigos",
          href: "/alunos-registros-antigos",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),

    canEdit() {
      if (!this.user.role) return false;
      return !["teacher", "student", "parent"].includes(this.user.role[0]);
    },
  },

  methods: {
    getStudentName(studentId) {
      let student = this.students.find((s) => s.id === studentId);
      return student && student.User ? student.User.name : "Aluno" + " " + studentId;
    },

    async downloadStudentReport(studentId) {
      try {
        this.loading = true;
        const response = await HttpRda.get(
          `/bimontly-report/${studentId}/report?version=1`,
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;

        const fileName = `relatorio-periodico-antigo-${this.getStudentName(studentId)}-${moment().format("DD-MM-YYYY")}.pdf`

        link.setAttribute(
          "download",
          fileName
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);

        this.loading = false;
        this.$swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Relatório baixado com sucesso.",
        });
      } catch (error) {
        this.loading = false;
        this.$swal.fire({
          icon: "error",
          title: "Erro!",
          text: error.response?.data?.error || "Erro ao baixar relatório",
        });
        return error;
      }
    },

    ...mapActions({
      getStudent: "community/getStudent",
    }),

    orderbyName() {
      const data = this.students.filter((d) => d.User !== null);
      return data.sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];
        if (a.User.name < b.User.name) return order[0];
        else if (a.User.name > b.User.name) return order[1];

        return 0;
      });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.cycle_select = id;
      if (this.cycle_select == 8 || this.cycle_select == 6) {
        this.nameCol = "Ed. Infantil";
      } else {
        this.nameCol = "Tutoria";
      }
      this.currentPage = 1;
      this.clearClassromms();
      this.listStudents();
    },

    callbackClassrooms({ id }) {
      this.classroom = id !== -1 ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    callbackStudentsStatus({ id }) {
      this.is_active = id !== null ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    async listStudents(page = 1) {
      const params = {
        limit: this.perPage,
        page,
        search: this.search,
        cycle: this.cycle,
        is_active: this.is_active,
        classroom: this.classroom,
      };

      if (this.search) {
        delete params.limit;
      }

      this.filter = { ...params };
      delete this.filter.limit;
      delete this.filter.page;
      Object.keys(this.filter).forEach(
        (key) =>
          (this.filter[key] == null || this.filter[key] == "") &&
          delete this.filter[key]
      );

      await HttpRda.get("/students?include=cycle", { params })
        .then((res) => {
          this.students = res.data.students;
          this.count = res.data.count;
          this.wasRequestMade = true;
        })
        .catch(() => {
        });
    },

    async searchStudent() {
      this.nameCol = "Tutoria";
      this.currentPage = 1;
      this.listStudents();
    },

    clearFields() {
      this.nameCol = "Tutoria";
      this.search = "";
      this.currentPage = 1;
      this.listStudents();
    },

    clearCycles() {
      this.nameCol = "Tutoria";
      this.cycle = null;
      this.currentPage = 1;
      this.listStudents();
    },

    clearClassromms() {
      this.classroom = null;
      this.currentPage = 1;
      this.listStudents();
    },

    clearStudentsStatus() {
      this.is_active = null;
      this.currentPage = 1;
      this.listStudents();
    },

    setStudent(student) {
      this.studentToEdit = student;
    },
  },

  watch: {
    currentPage() {
      this.listStudents(this.currentPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  async created() {
    await this.listStudents();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.btn-period {
  background-color: transparent !important;
  border-color: transparent !important;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tags_count {
    background-color: #68c5b7;
    font-size: 18px;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    padding: 3px 10px;
    height: max-content;
  }
}

.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;

  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  p {
    margin: 0;
  }

  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;

      .comma {
        margin-right: 4px;
      }

      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }

  .cycles {
    display: flex;
    flex-wrap: wrap;

    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.outline {
  color: #fff !important;
  background: transparent;
  max-width: 220px !important;
  margin-right: 20px;

  &:hover {
    color: #68c5b7 !important;
  }
}
</style>

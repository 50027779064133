var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-rda"},[_c('b-row',{staticClass:"pt-5 mt-4 mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Bem-vindo(a)!")])]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"normal",attrs:{"to":{
          name: 'perfil',
        }}},[_vm._v("Meus dados")])],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"profile-row"},[_c('b-col',[(_vm.currentUser.photo == null || _vm.currentUser.photo == '')?_c('img',{attrs:{"src":require("@/assets/images/default-photo.png"),"height":"64","width":"64","alt":""}}):_c('img',{attrs:{"width":"64","height":"64","src":("" + (_vm.currentUser.photo)),"alt":""}}),_c('div',{staticClass:"content-profile"},[_c('h5',[_vm._v(_vm._s(_vm.currentUser.name))]),(
                _vm.students && _vm.students.Classroom && _vm.students.Classroom.year_id
              )?_c('p',[_vm._v(" Ciclo: "+_vm._s(_vm.students.Cycle.name)+" - "+_vm._s(_vm.students.Classroom.year_id)+"º ano ")]):_c('p',[_vm._v("Ciclo: "+_vm._s(_vm.students.Cycle.name))])])])],1)]),_c('b-col',[_c('b-row',[_c('b-col',[_c('router-link',{staticClass:"dash-box contents",attrs:{"to":{
              name: 'registrar-aprendizagem',
              params: { id: _vm.currentUser.student_id },
            }}},[_c('p',[_vm._v("Conteúdos")]),_c('h1',[_vm._v(_vm._s(_vm.topicsLength))])])],1),(false)?_c('b-col',[_c('router-link',{staticClass:"dash-box scripts",attrs:{"to":{ name: 'roteiros-aluno' }}},[_c('p',[_vm._v("Roteiros")]),_c('h1',[_vm._v(_vm._s(_vm.syllabuses.length))])])],1):_vm._e(),_c('b-col',[_c('router-link',{staticClass:"dash-box comments",attrs:{"to":{
              name: 'faltas-e-presencas',
              params: { student_id: _vm.currentUser.student_id },
            }}},[_c('p',[_vm._v("Frequência")]),_c('h1',[_vm._v(_vm._s(_vm.attendances.frequency)+"%")])])],1),(_vm.students.Cycle.id !== 8 && _vm.students.Cycle.id !== 6)?_c('b-col',[_c('router-link',{staticClass:"dash-box comments",staticStyle:{"background-color":"#4589b2"},attrs:{"to":{
              name: 'dashboard-tutoria-aluno',
              params: {
                id: _vm.currentUser.student_id,
              },
            }}},[_c('h2',[_vm._v("Tutoria")])])],1):_vm._e(),(_vm.students.Cycle.id === 8 || _vm.students.Cycle.id === 6)?_c('b-col',[_c('router-link',{staticClass:"dash-box comments",staticStyle:{"background-color":"#4589b2"},attrs:{"to":{
              name: 'dashboard-timeline-aluno-parent',
              params: {
                id: _vm.currentUser.student_id,
              },
            }}},[_c('h2',[_vm._v("Ed. Infantil")])])],1):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"pt-5 mt-5 mb-4"},[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Conteúdos em estudo")])])],1),_c('b-row',[(!_vm.topicsFormated.length > 0)?_c('b-col',[_c('p',[_vm._v("Não há conteúdos em estudo")])]):_c('b-col',[_c('carousel',{attrs:{"perPageCustom":[
          [360, 3],
          [1366, 6] ],"paginationColor":"$grey5"}},_vm._l((_vm.topicsFormated),function(subject){return _c('slide',{key:subject.id},[_c('div',{staticClass:"box-scripts"},[_c('b-row',[_c('b-col',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showModal(subject)}}},[_c('SubjectsIcon',{attrs:{"value":subject.designation}}),_c('h5',[_vm._v(_vm._s(subject.designation))])],1)])],1),_c('b-row',{attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',[(subject.topics && subject.topics.length > 1)?_c('span',[_vm._v("Há "),_c('strong',[_vm._v(_vm._s(subject.topics.length))]),_vm._v(" conteúdos com status \"EE\"")]):_vm._e(),(subject.topics && subject.topics.length == 1)?_c('span',[_vm._v("Há "),_c('strong',[_vm._v(_vm._s(subject.topics.length))]),_vm._v(" conteúdos com status \"EE\"")]):_vm._e(),(subject.topics && subject.topics.length == 0)?_c('span',[_vm._v("Não há conteúdos")]):_vm._e()]),_c('div',{staticClass:"w-100"})],1)],1)])}),1)],1)],1),_c('SubjectsProgressBars',{attrs:{"percentages":_vm.percentages,"subjects":_vm.subjects,"desiredPercentage":_vm.desiredPercentage}}),(_vm.subjectSelected)?_c('b-modal',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"id":"modal-script","hide-footer":"","title":_vm.subjectSelected.designation}},_vm._l((_vm.subjectSelected.competences),function(competence,index){return _c('b-row',{key:("competence-" + index),staticClass:"rda-viewlist"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(competence.description))])]),_vm._l((competence.topics),function(topic,indexS){return _c('b-col',{key:("student-" + indexS),attrs:{"cols":"12"}},[_c('div',{staticClass:"list-teacher item-rda-viewlist"},[_c('div',{staticClass:"cols text-col"},[_c('p',[_vm._v(_vm._s(topic))])]),_c('div',{staticClass:"cols"},[_c('p',{staticClass:"tags warning"},[_vm._v("EE")])])])])})],2)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }